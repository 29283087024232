import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../Services.css"; // Import your custom CSS file

const Services = () => {
  return (
    <div id="services" className="scrollto clearfix">
      <Container>
        <Row className="no-padding-bottom clearfix">
          <Col md={4}>
            {/* User Testimonial */}
            <blockquote className="testimonial text-right bigtest">
              <q>
              Result oriented work can only lead you to the success

              </q>
              <footer>— Lucky&nbsp;Soni, CEO </footer>
            </blockquote>
            {/* End of Testimonial */}
          </Col>
          <Col md={4}>
            <div >
              <p className="services-heading">BELIEVING</p>
              <p className="services-title">Focusing on what matters most</p>
              {/* <p className="section-subtitle">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam!
              </p> */}
            </div>
            <p>
              At Sharpless Technology LLC, we work on building everlasting
              relationships with our clients, employees, and the communities we
              serve. This is the era of changing technology, so we are here to
              work accordingly. We’re constantly working on learning and
              evolving to follow the latest trends. But with the change in
              trends, we are committed to the things that never change, like our
              commitment to quality, our dedication to our clients, and our
              belief that people make organizations great.
            </p>
            {/* Use React Bootstrap Button component */}
            {/* <a
              href="https://youtu.be/dQw4w9WgXcQ"
              target="_blank"
              data-videoid="UYJ5IjBRlW8"
              data-videosite="youtube"
              className="btn button video link-lightbox serv-but"
            >
              WATCH VIDEO <i className="fa fa-play" aria-hidden="true"></i>
            </a> */}
          </Col>
          <Col md={4}>
            <img src="images/dancer.jpg" alt="Dancer" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Services;
