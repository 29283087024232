import React from "react";
import "../Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FaMapMarker, FaPhone, FaEnvelope } from "react-icons/fa";
import { CiLocationOn } from "react-icons/ci";
import { FaLocationDot } from "react-icons/fa6";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import { HomeRepairServiceTwoTone } from "@mui/icons-material";
import RoomIcon from "@mui/icons-material/Room";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { pink, red } from "@mui/material/colors";

const Footer = () => {
  return (
    <footer className="footer-distributed">
      <div className="footer-left">
        <a href="/">
          <img
            src={`images/logo footer.png`}
            alt="Landing Page"
            className="gallery-image"
          />
        </a>
        <p className="footer-links">
          <a href="/" className="link-1 lks">
            Home
          </a>
          <a href="/our-solutions" className="lks">
            Our Solutions
          </a>
          {/* <a href="/industries" className="lks">
            Industries
          </a> */}
          {/* <a href="/partner" className="lks">Partner</a> */}
          <a href="/about-company" className="lks">
            About Us
          </a>
          <a href="/contact-us" className="lks">
            Contact Us
          </a>
        </p>
        <p className="footer-company-name">
          All Rights Reserved @2021{" "}
          {/* <a href="/private-policy" className="addedLinks">
            Private Policy
          </a>{" "}
          |{" "}
          <a href="/user-agreement" className="addedLinks">
            User Agreement
          </a> */}
        </p>
      </div>

      <div className="footer-center">
        <div className="contactDetails">
          <RoomIcon sx={{ color: pink[50] }} />
          <p className="address">
            4030 Wake Forest Rd #349,
            <br /> Raleigh, NC 27609, United States
          </p>
        </div>
        <div className="contactDetails">
          <PhoneEnabledIcon sx={{ color: pink[50] }} />
          <p className="address">
            <a href="tel:+91 9399968191" className="address">
              +91 9399968191
            </a>
          </p>
        </div>
        <div className="contactDetails">
          <PhoneEnabledIcon sx={{ color: pink[50] }} />
          <p className="address">
            <a href="tel:+1 5138155058" className="address">
            +1 5138155058
            </a>
          </p>
        </div>
        <div className="contactDetails">
          <EmailIcon sx={{ color: pink[50] }} />
          <p className="address">
            <a href="mailto:hr@sharplesstechnology.com">
              hr@sharplesstechnology.com
            </a>
          </p>
        </div>
      </div>

      <div className="footer-right">
        <p className="footer-company-about">
          <a href="/about-company" className="addedLinks fixAbout">
            About the company
          </a>
          <p className="aboutDescription">
            Success Through Next <br />
            Generation Innovation
          </p>
        </p>
        <div className="footer-icons">
          <a
            href="https://www.linkedin.com/company/sharpless-technology/"
            target="_blank"
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          {/* <a href="#">
            <FontAwesomeIcon icon={faXTwitter} />
          </a> */}
          <a href="#">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          {/* <a href="#">
            <FontAwesomeIcon icon={faFacebook} />
          </a> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
