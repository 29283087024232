import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.css";
import "../navbar.css";

const boldTextStyle = {
  fontWeight: "bold",
  marginRight: "10px",
  marginTop: "20px",
  // marginBottom: "20px",
};

const buttonStyle = {
  color: "white",
  fontWeight: "bold",
  marginRight: "10px",
  marginTop: "25px",
  marginBottom: "20px",
  padding: "5px 20px 5px",
  borderRadius: "10px",
  border: "none",
  backgroundColor: "#f49299",
  cursor: "pointer",
  transition: "background-color 0.3s, transform 0.3s",
  textDecoration: "none",
  fontWeight: "bold",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
};

const AppNavbar = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const [expanded, setExpanded] = useState(false);

  const toggleNavbar = () => {
    setExpanded(!expanded);
  };

  const closeNavbar = () => {
    if (expanded) {
      setExpanded(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;

      setVisible(
        (prevScrollPos > currentScrollPos &&
          prevScrollPos - currentScrollPos > 70) ||
          currentScrollPos < 10
      );

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  return (
    <Navbar
      bg="light"
      expand="lg"
      expanded={expanded}
      className={`fixed-top ${visible ? "nav-show" : "nav-hide"}`}
    >
      <Container>
        <Navbar.Brand as={Link} to="/" style={boldTextStyle}>
          <img
            src={`images/logo.png`}
            alt="Landing Page"
            className="gallery-image"
          />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="navbarTogglerDemo01"
          onClick={toggleNavbar}
        />
        <Navbar.Collapse id="navbarTogglerDemo01">
          <Nav className="ms-lg-auto">
            <Nav.Link
              as={Link}
              to="/"
              className="nav-link"
              style={boldTextStyle}
              onClick={closeNavbar}
            >
              Home
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/our-solutions"
              className="nav-link"
              style={boldTextStyle}
              onClick={closeNavbar}
            >
              Our Solutions
            </Nav.Link>
            {/* <Nav.Link as={Link} to="/industries" className="nav-link" style={boldTextStyle} onClick={closeNavbar}>Industries</Nav.Link> */}
            {/* <Nav.Link as={Link} to="/partner" className="nav-link" style={boldTextStyle} onClick={closeNavbar}>Partner</Nav.Link> */}
            <Nav.Link
              as={Link}
              to="/about-company"
              className="nav-link"
              style={boldTextStyle}
              onClick={closeNavbar}
            >
              About Company
            </Nav.Link>
            <Button
              as={Link}
              to="/contact-us"
              style={buttonStyle}
              onClick={closeNavbar}
            >
              Contact Us
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default AppNavbar;
