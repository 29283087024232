import React from "react";
import "../AboutCompany.css";

const AboutCompany = () => {
  return (
    <div className="text-center about-container">
      <p className="aboutHeading">About Company</p>
      <p className="content">
        Helping organizations to work on productivity into their operations and
        pave faster pathways to growth. With Sharpless, businesses can grow in
        an economical way and can navigate the web of technological
        opportunities with more confidence and less guesswork.
      </p>

      <p className="content">
        Our story began in 2020 with a group of friends who came together with
        the solution to a problem they were facing - the lack of
        technology-centric people in big giants. They realized that these
        organizations often lack the technical expertise to quickly embrace new
        opportunities, stunting their growth.
      </p>

      <p className="content">
        The answer led us on a journey of three years that shaped what Sharpless
        Technology LLC is today.
      </p>
        <p className="heading">Our Mission</p>
        <p className="content">
          To help businesses with the world's best talent and solution to
          maximize productivity and reach milestones.
        </p>

        <p className="heading">Our Vision</p>
        <p className="content">
          To become the most trusted provider of the best talent for businesses
          worldwide and empower them in the global era.
        </p>
 

        <p className="heading">Our Values</p>
        <p className="content">
          Our values stay with the pillars of Sharpless. At Sharpless Technology
          LLC, we work on building everlasting relationships with our clients,
          employees, and the communities we serve. This is the era of changing
          technology, so we are here to work accordingly. We’re constantly
          working on learning and evolving to follow the latest trends. But with
          the change in trends, we are committed to the things that never
          change, like our commitment to quality, our dedication to our clients,
          and our belief that people make organizations great.
        </p>

        <ul className="list-styling">
          <li>Flexibility: Adaptable and always ready to pivot.</li>
          <li>Reliability: A team you can count on, no matter what.</li>
          <li>Quality: World-class solutions and candidates.</li>
          <li>Passion: A drive to be the best in everything we do.</li>
          <li>Integrity: Doing the right thing, especially when it's hard.</li>
          <li>Innovation: A willingness to challenge the status quo.</li>
          <li>Teamwork: Working together to achieve collective success.</li>
          <li>Communication: Transparency and collaboration at every turn.</li>
        </ul>

      {/* <section className="leadership">
        <h2>Our Leadership Team</h2>
        <p>
          We have the brightest minds in the industry. Combining technical and
          business expertise, our leadership team is poised to take Sharpless
          Technology LLC to unique frontiers.
        </p>

        <ul>
          <li>CEO: Lucky Soni</li>
          <li>Director: Supriya Soni</li>
          <li>Director: Harilal</li>
        </ul>
      </section> */}
    </div>
  );
};

export default AboutCompany;
