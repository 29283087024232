import React, { useEffect, useState } from "react";
import { Container, Row, Col, Fade } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Nav from "react-bootstrap/Nav";
import {
  faRobot,
  faCloud,
  faLink,
  faRocket,
  faTablet,
  faPeopleGroup,
} from "@fortawesome/free-solid-svg-icons";
import "../Introduction.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link, NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Introduction = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const linkStyle = {
    textDecoration: "None",
    color: "black",
    fontWeight: "bold",
  };

  return (
    <div>
      <Container>
        <section id="about" className={`introduction scrollto`}>
          <Row className="clearfix">
            <Col md={3}>
              <div className="intro-heading">
                <h3>SUCCESS</h3>
                <h2 className="intro-title">
                  Our Solutions on How We Help You To Grow your Business
                </h2>
                {/* <p className="section-subtitle">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam!
                </p> */}
              </div>
            </Col>
            <Col md={9}>
              {/* Icon Block 1 */}
              <div
                className={`col-2 icon-block icon-top wow fixTop`}
                data-aos="fade-up"
              >
                <div className="icon">
                  <FontAwesomeIcon icon={faRobot} size="2x" />
                </div>
                <div className="icon-block-description">
                  <HashLink
                    to="/our-solutions#workday"
                    smooth
                    style={linkStyle}
                  >
                    Workday
                  </HashLink>
                  <p>
                    Workday consultants customize HR and financial software,
                    aligning with client needs, and overseeing implementation.
                  </p>
                </div>
              </div>
              {/* Icon Block 2 */}
              <div
                className={`col-2 icon-block icon-top wow fixTop}`}
                data-aos="fade-up"
              >
                <div className="icon">
                  <FontAwesomeIcon icon={faCloud} size="2x" />
                </div>
                <div className="icon-block-description">
                  <HashLink
                    to="/our-solutions#salesforce"
                    smooth
                    style={linkStyle}
                  >
                    Salesforce
                  </HashLink>
                  <p>
                    Salesforce empowers businesses with a customizable CRM
                    platform for sales, marketing, and customer service.
                  </p>
                </div>
              </div>
              <div
                className={`col-2 icon-block icon-top wow fixTop`}
                data-aos="fade-up"
              >
                {/*Icon*/}
                <div className="icon">
                  <FontAwesomeIcon icon={faLink} size="2x" />
                </div>
                {/*Icon Block Description*/}
                <div className="icon-block-description">
                  <HashLink
                    to="/our-solutions#business-intelligence"
                    smooth
                    style={linkStyle}
                  >
                    Business Analyst
                  </HashLink>
                  <p>
                    Business Intelligence (BI) gathers, analyzes data, enabling
                    informed, data-driven decision-making for organizations.
                  </p>
                </div>
              </div>
              {/*End of Icon Block*/}
              {/*Icon Block*/}
              <div
                className={`col-2 icon-block icon-top wow fixTop`}
                data-aos="fade-up"
              >
                {/*Icon*/}
                <div className="icon">
                  <FontAwesomeIcon icon={faRocket} size="2x" />
                </div>
                {/*Icon Block Description*/}
                <div className="icon-block-description">
                  <HashLink
                    to="/our-solutions#software-quality"
                    smooth
                    style={linkStyle}
                  >
                    Software Quality
                  </HashLink>
                  <p>
                    Software quality ensures reliability, efficiency, and
                    security through testing, and
                    maintenance, vital for user satisfaction and business
                    success.
                  </p>
                </div>
              </div>
              {/*End of Icon Block*/}
              {/*Icon Block*/}
            </Col>
          </Row>
        </section>
      </Container>
    </div>
  );
};

export default Introduction;
