import React from "react";
import "../contact.css";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";

const SERVICE_ID = "service_xgwv1f5";
const TEMPLATE_ID = "template_v205bpk";
const PUBLIC_KEY = "6xUhb4pSq3HJbmnSy";

const ContactUs = () => {
  const handleOnSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const inputs = form.querySelectorAll("input");
    let allFieldsFilled = true;

    inputs.forEach((input) => {
      if (!input.value.trim()) {
        allFieldsFilled = false;
      }
    });

    if (!allFieldsFilled) {
      Swal.fire({
        icon: "error",
        title: "Please fill in all fields",
        text: "All fields are required.",
      });
      return;
    }

    emailjs
      .sendForm(SERVICE_ID, TEMPLATE_ID, form, PUBLIC_KEY)
      .then((result) => {
        console.log(result.text);
        Swal.fire({
          icon: "success",
          title: "Message Sent Successfully",
        });
      })
      .catch((error) => {
        console.log(error.text);
        Swal.fire({
          icon: "error",
          title: "Oops, something went wrong",
          text: error.text,
        });
      });

    form.reset();
  };
  return (
    <div class="contactForm">
      <div class="contact_us_green">
        <div class="responsive-container-block big-container">
          <div class="responsive-container-block container">
            <div
              class="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-7 wk-ipadp-10 line"
              id="i69b-2"
            >
              <form onSubmit={handleOnSubmit} class="form-box">
                <div class="container-block form-wrapper">
                  <div class="head-text-box">
                    <p class="text-blk contactus-head">Contact us</p>
                    <p class="text-blk contactus-subhead">
                      Contact us at below details for any future assistance
                    </p>
                  </div>
                  <div class="responsive-container-block">
                    <div
                      class="responsive-cell-block wk-ipadp-6 wk-tab-12 wk-mobile-12 wk-desk-6"
                      id="i10mt-6"
                    >
                      <p class="text-blk input-title">FIRST NAME</p>
                      <input class="input" id="first_name" name="first_name" />
                    </div>
                    <div class="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
                      <p class="text-blk input-title">LAST NAME</p>
                      <input class="input" id="last_name" name="last_name" />
                    </div>
                    <div class="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
                      <p class="text-blk input-title">EMAIL</p>
                      <input class="input" id="email" name="email" />
                    </div>
                    <div class="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
                      <p class="text-blk input-title">PHONE NUMBER</p>
                      <input
                        class="input"
                        id="phone_number"
                        name="phone_number"
                      />
                    </div>
                    <div
                      class="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-12 wk-ipadp-12"
                      id="i634i-6"
                    >
                      <p class="text-blk input-title">
                        WHAT DO YOU HAVE IN MIND
                      </p>
                      <textarea
                        class="textinput"
                        id="message"
                        placeholder="Please enter query..."
                        name="message"
                      ></textarea>
                    </div>
                  </div>
                  <div class="btn-wrapper">
                    <button class="submit-btn">Submit</button>
                  </div>
                </div>
              </form>
            </div>
            <div
              class="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-5 wk-ipadp-10"
              id="ifgi"
            >
              <div class="container-box">
                <div class="text-content">
                  <p class="text-blk contactus-head">Contact us</p>
                  <p class="text-blk contactus-subhead">
                    Contact us at below details for any future assistance
                  </p>
                </div>
                <div class="workik-contact-bigbox">
                  <div class="workik-contact-box">
                    <div class="phone text-box">
                      <img
                        class="contact-svg"
                        src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/ET21.jpg"
                      />
                      <p class="contact-text">
                        <a href="tel:+91 9399968191" class="contact-text">
                          +91 9399968191
                        </a>
                      </p>
                    </div>
                    <div class="phone text-box">
                      <img
                        class="contact-svg"
                        src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/ET21.jpg"
                      />
                      <p class="contact-text">
                        <a href="tel:+1 5138155058" class="contact-text">
                          +1 5138155058
                        </a>
                      </p>
                    </div>
                    <div class="address text-box">
                      <img
                        class="contact-svg"
                        src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/ET22.jpg"
                      />
                      <p class="contact-text">hr@sharplesstechnology.com</p>
                    </div>
                    <div class="mail text-box">
                      <img
                        class="contact-svg"
                        src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/ET23.jpg"
                      />
                      <p class="contact-text">
                        4030 Wake Forest Rd #349,
                        <br /> Raleigh, NC 27609, United States
                      </p>
                    </div>
                  </div>
                  <div class="social-media-links">
                    <a
                      href="https://www.linkedin.com/company/sharpless-technology/"
                      target="_blank"
                    >
                      <FontAwesomeIcon
                        className="social-svg"
                        icon={faLinkedin}
                        size="2x"
                      />
                    </a>
                    {/* <a href="">
                      <FontAwesomeIcon
                        className="social-svg"
                        icon={faXTwitter}
                        size="2x"
                      />
                    </a> */}
                    <a href="">
                      <FontAwesomeIcon
                        className="social-svg"
                        icon={faInstagram}
                        size="2x"
                      />
                    </a>
                    {/* <a href="">
                      <FontAwesomeIcon
                        className="social-svg"
                        icon={faFacebook}
                        size="2x"
                      />
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
