import React from "react";
import { BrowserRouter as Router, Routes, Route as ReactRoute } from "react-router-dom";
import "./App.css";

import AppNavbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import OurSolutions from "./pages/OurSolutions";
import Industries from "./pages/Industries";
import Partner from "./pages/Partner";
import AboutCompany from "./pages/AboutCompany";
import ContactUs from "./pages/ContactUs";
import UserAgreement from "./pages/UserAgreement"
import PrivatePolicy from "./pages/PrivatePolicy";

function App() {
  return (
    <Router>
      <div className="App">
        <AppNavbar />
        <Routes>
          <ReactRoute path="/" element={<Home />} />
          <ReactRoute path="/our-solutions" element={<OurSolutions />} />
          <ReactRoute path="/industries" element={<Industries />} />
          <ReactRoute path="/partner" element={<Partner />} />
          <ReactRoute path="/about-company" element={<AboutCompany />} />
          <ReactRoute path="/contact-us" element={<ContactUs />} />
          <ReactRoute path="/user-agreement" element={<UserAgreement />} />
          <ReactRoute path="/private-policy" element={<PrivatePolicy />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
