import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import "../Gallery.css";
import Aos from "aos";
import "aos/dist/aos.css";

const Gallery = () => {

useEffect(() => {
  Aos.init({duration: 2000});
},[]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setIsModalOpen(false);
  };

  const images = [1, 2, 3, 4, 5, 6];

  return (
    <section id="gallery" className="text-center scrollto clearfix">
      <div className="container">
        <Row className="featherlight-gallery" data-featherlight-filter="a">
          {images.map((imageNumber, index) => (
            <Col
              key={index}
              md={4}
              sm={6}
              xs={12}
              className={`col-3 wow fadeIn`}
              style={{
                visibility: "visible",
                animationDelay: `${index * 0.2 + 0.1}s`,
                animationName: "fadeIn",
              }}
            >
              <img
                src={`images/gallery-images/gallery-image-${imageNumber}.jpg`}
                alt={`Image ${imageNumber}`}
                className="gallery-image"
                onClick={() => openModal(imageNumber)}
                data-aos = "fade-up"
              />
            </Col>
          ))}
        </Row>
      </div>
      <Modal open={isModalOpen} onClose={closeModal} center styles={{ modal: { width: "100%", maxHeight: "100%" }} }>
        <Carousel styles={{ carousel: { background: "transparent", width: "100%", maxHeight: "100%" } }}>
          {images.map((imageNumber, index) => (
            <div key={index}>
              <img
                src={`images/gallery-images/gallery-image-${imageNumber}.jpg`}
                alt={`Image ${imageNumber}`}
              />
            </div>
          ))}
        </Carousel>
      </Modal>
    </section>
  );
};

export default Gallery;
