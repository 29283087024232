import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Parallax } from "react-parallax";
import bannerImage from "../images/banner-image-1.jpg";
import "../Banner.css";

const Header = () => {
  return (
    <header id="banner" className="scrollto clearfix">
      <Parallax bgImage={bannerImage} strength={120} className="parallax-visibility">
        <div id="banner-content" className="row clearfix">
          <Container>
            <Row>
              <Col xs={12} lg={8}>
                <div className="section-heading">
                  <h1>
                    Accelerate Power Of Technology With The Precision Of People
                  </h1>
                  <h2>
                    Products that empower business are the future of product development. Solve problems that address the ecosystem as a whole.
                  </h2>
                </div>
                <a href="/contact-us" className="btn button video ban-but link-lightbox">
                  Get in touch
                </a>
              </Col>
            </Row>
          </Container>
        </div>
      </Parallax>
      <div id="banner-content" className="row clearfix without-parallax-visibility">
        <Container>
          <Row>
            <Col xs={12} lg={8}>
              <div className="section-heading">
                <h1>
                  Accelerate Power Of Technology With The Precision Of People
                  </h1>
                <h2>
                  Products that empower business are the future of product development. Solve problems that address the ecosystem as a whole.
                </h2>
              </div>
              <a href="/contact-us" className="btn button video ban-but link-lightbox">
                Get in touch
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </header>
  );
};

export default Header;
