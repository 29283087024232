import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import "../Pages.css";
import Image  from "react-bootstrap/Image";
import Aos from "aos";
import "aos/dist/aos.css";

const OurSolutions = () => {

  useEffect(() => {
    Aos.init({duration: 2000});
  },[]);

  const buttonStyle = {
    fontSize: "16px",
    marginTop: "10px",
    transition: "all 0.3s linear",
    backgroundColor: "#e8d95b",
    border: "none",
    color: "black",
  };

  const bottomStyle = {
    marginBottom: "15vh"
  }

  const lineStyle = {
    lineHeight: "6vh"
  }

  return (
    <>
      <div className="content-division" style={bottomStyle}>
        <div className="fs-1 text-center fw-bolder pt-5 pb-lg-3 pb-md-4"><p className="aboutHeading" style={lineStyle}>Our Solutions</p></div>
        <Container className="text-center ms-auto">
          <Row>
            <Col className="fs-1 fw-bold pt-2 pb-2">
              Cloud Transformation
            </Col>
          </Row>
          <Row>
            <Col sm={6} className="imageAlignment">
              <Image src="./images/cloud_transformation.png" data-aos="fade-right"/>
            </Col>
            <Col sm={6} className="fs-5 lh-lg alignment" data-aos="fade-left">
              <p>
                Cloud transformation is a strategic shift in an organization's
                IT strategy, involving the migration of on-premises
                infrastructure and applications to cloud-based platforms. This
                process enhances agility, scalability, and cost-efficiency while
                fostering innovation. It begins with a careful assessment of
                existing IT assets and a roadmap for migration, considering
                factors like compliance and security.
              </p>
              <p>
                The choice of cloud service models (IaaS, PaaS, SaaS) and
                deployment models (public, private, hybrid) is crucial.
                Migration itself can range from straightforward lifts-and-shifts
                to complex re-architecting for cloud-native environments.
                Post-migration, integration, optimization, security, and
                resource management are vital for success.
              </p>
            </Col>
          </Row>
          <Row>
            <Col className="fs-1 fw-bold pt-2 pb-2 mt-5">
              Robotic Process Automation
            </Col>
          </Row>
          <Row>
            <Col sm={6} className="imageAlignment">
              <Image src="./images/robotic_process_automation.png" data-aos="fade-right"/>
            </Col>
            <Col sm={6} className="fs-5 lh-lg alignment" data-aos="fade-left">
              <p>
                Robotic Process Automation (RPA) is a transformative technology
                that uses software robots or "bots" to automate repetitive,
                rule-based tasks in business processes. These bots mimic human
                interactions with computer systems, allowing organizations to
                streamline operations, increase efficiency, and reduce human
                error.
              </p>
              <p>
                RPA can be applied across various industries, from finance and
                healthcare to manufacturing and customer service. It excels in
                tasks like data entry, invoice processing, data extraction, and
                report generation. RPA doesn't require complex coding; it's
                designed to be user-friendly, making it accessible to business
                users.
              </p>
            </Col>
          </Row>
          <Row>
            <Col className="fs-1 fw-bold pt-2 pb-2 mt-5">
              Blockchain Development
            </Col>
          </Row>
          <Row>
            <Col sm={6} className="imageAlignment">  
              <Image src="./images/blockchain_development.png" data-aos="fade-right"/>
            </Col>
            <Col sm={6} className="fs-5 lh-lg alignment" data-aos="fade-left">
              <p>
                Blockchain development is the process of designing, coding, and
                deploying decentralized digital ledgers known as blockchains.
                Blockchains are distributed databases that record transactions
                across a network of computers in a secure and immutable manner.
                This technology gained prominence due to its role in powering
                cryptocurrencies like Bitcoin, but its applications extend far
                beyond finance.
              </p>
              <p>
                In blockchain development, developers create smart contracts,
                decentralized applications (DApps), and custom blockchain
                solutions tailored to various industries such as supply chain,
                healthcare, finance, and more. These applications offer
                transparency, security, and trust by eliminating intermediaries
                and providing a tamper-proof record of transactions.
              </p>
            </Col>
          </Row>
          <Row id="salesforce">
            <Col className="fs-1 fw-bold pt-2 pb-2 mt-5">
              Salesforce
            </Col>
          </Row>
          <Row>
            <Col sm={6} className="imageAlignment">
              <Image src="./images/salesforce.png" data-aos="fade-right"/>
            </Col>
            <Col sm={6} className="fs-5 lh-lg alignment" data-aos="fade-left">
              <p>
                Salesforce is a leading cloud-based Customer Relationship
                Management (CRM) platform that empowers businesses to manage and
                streamline their sales, marketing, customer service, and data
                analytics operations. It provides a comprehensive suite of tools
                and services for organizations to build meaningful and lasting
                relationships with their customers.
              </p>
              <p>
                Salesforce offers a range of features, including contact and
                lead management, sales forecasting, marketing automation,
                customer support, and data analytics. It's highly customizable
                and can be tailored to suit the unique needs of businesses
                across various industries.
              </p>

              <Button
                as={Link}
                to="https://www.salesforce.com/in/"
                style={buttonStyle}
                target="_blank"
              >
                More
              </Button>
            </Col>
          </Row>
          <Row>
            <Col className="fs-1 fw-bold pt-2 pb-2 mt-5">
              Custom Application Development
            </Col>
          </Row>
          <Row>
            <Col sm={6} className="imageAlignment">
              <Image src="./images/custom_application_development.png" data-aos="fade-right"/>
            </Col>
            <Col sm={6} className="fs-5 lh-lg alignment" data-aos="fade-left">
              <p>
                Custom application development is the process of creating
                software applications tailored specifically to meet the unique
                needs and requirements of a business or organization. Unlike
                off-the-shelf software, custom applications are built from the
                ground up, allowing for precise alignment with an organization's
                workflows, processes, and objectives.
              </p>
              <p>
                This development approach begins with a thorough analysis of the
                client's business needs and goals. Developers then design, code,
                and test the application, ensuring it addresses specific
                challenges and integrates seamlessly with existing systems.
                Custom applications can range from web and mobile apps to
                complex enterprise solutions.
              </p>
            </Col>
          </Row>
          <Row id="workday">
            <Col className="fs-1 fw-bold pt-2 pb-2 mt-5">
              Workday Consultant
            </Col>
          </Row>
          <Row>
            <Col sm={6} className="imageAlignment">
              <Image src="./images/workday_consultant.png" data-aos="fade-right"/>
            </Col>
            <Col sm={6} className="fs-5 lh-lg alignment" data-aos="fade-left">
              <p >
                A Workday consultant is a professional who specializes in the
                implementation, customization, and optimization of Workday, a
                cloud-based human resources (HR) and financial management
                software system. Workday consultants have expertise in
                configuring the Workday platform to align with an organization's
                HR, finance, and payroll processes.
              </p>
              <p>
                Their responsibilities include collaborating with clients to
                understand their business requirements, designing solutions
                within the Workday framework, and overseeing the implementation
                process. Workday consultants also provide guidance on best
                practices, data migration, integration with other systems, and
                post-implementation support.
              </p>
              <Button
                as={Link}
                to="https://www.workday.com/"
                style={buttonStyle}
                target="_blank"
              >
                More
              </Button>
            </Col>
          </Row>
          <Row id="software-quality">
            <Col className="fs-1 fw-bold pt-2 pb-2 mt-5">
              Software Quality
            </Col>
          </Row>
          <Row>
            <Col sm={6} className="imageAlignment">
              <Image src="./images/software_quality.png" data-aos="fade-right"/>
            </Col>
            <Col sm={6} className="fs-5 lh-lg alignment" data-aos="fade-left">
              <p>
                Software quality is crucial for the success of any application or system, ensuring reliability,
                efficiency, and security. It should be user-friendly, easy to maintain, and compliant with 
                standards. Achieving software quality involves thorough testing, rigorous development processes,
                and ongoing maintenance to improve performance and usability.
              </p>
              <p>
              In the digital age, software quality is crucial for user satisfaction, productivity, and business 
              success. Poor-quality software can lead to frustration, security breaches, and financial losses. 
              Therefore, developers and organizations must prioritize software quality throughout the development
              lifecycle to deliver products that meet or exceed user expectations.
              </p>
            </Col>
          </Row>
          <Row id="business-intelligence">
            <Col className="fs-1 fw-bold pt-2 pb-2 mt-5">
              Business Intelligence
            </Col>
          </Row>
          <Row>
            <Col sm={6} className="imageAlignment">
              <Image src="./images/business_intelligence.png" data-aos="fade-right"/>
            </Col>
            <Col sm={6} className="fs-5 lh-lg alignment" data-aos="fade-left" >
              <p>
                Business Intelligence (BI) refers to a set of technologies,
                processes, and strategies that enable organizations to collect,
                analyze, and transform data into meaningful insights for
                informed decision-making. BI systems gather data from various
                sources, such as databases, spreadsheets, and external data
                streams, and then use tools like data visualization, reporting,
                and dashboards to present this information in a comprehensible
                format.
              </p>
              <p>
                BI helps organizations identify trends, patterns, and
                opportunities within their data, facilitating strategic
                planning, performance measurement, and data-driven
                decision-making. It plays a crucial role in areas like market
                analysis, customer behavior assessment, financial forecasting,
                and operational optimization.
              </p>
            </Col>
          </Row>
          <Row>
            <Col className="fs-1 fw-bold pt-2 pb-2 mt-5">
              Human Capital Management
            </Col>
          </Row>
          <Row>
            <Col sm={6} className="imageAlignment">
              <Image src="./images/human_capital_management.png" data-aos="fade-right" />
            </Col>
            <Col sm={6} className="fs-5 lh-lg alignment" data-aos="fade-left" >
              <p>
                Human Capital Management (HCM) is a comprehensive approach to
                managing and optimizing an organization's workforce. It
                encompasses a range of practices and strategies aimed at
                acquiring, developing, managing, and retaining talent
                effectively. Key elements of HCM include:
              </p>
              <p>
                HCM is crucial for attracting and retaining top talent,
                improving productivity, and ultimately achieving an
                organization's strategic objectives. It acknowledges that
                employees are a valuable asset, emphasizing their development
                and well-being as critical drivers of business success.
              </p>
              <Button
                as={Link}
                to="https://www.workday.com/en-us/products/human-capital-management/overview.html"
                style={buttonStyle}
                target="_blank"
              >
                More
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default OurSolutions;
