import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../Pages.css";

const Industries = () => {
  return (
    <>
      <div className="content-division">
        <div className="fs-1 text-center pt-5 fw-bolder">Industries</div>
        <Container className="pt-2 text-center ms-auto">
          <Row className="my-5">
            <Col sm={6} className="fs-3 fw-bold">
              Healthcare
            </Col>
            <Col sm={6}></Col>
          </Row>
          <Row className="my-5">
            <Col sm={6}></Col>
            <Col sm={6} className="fs-5 lh-lg alignment">
              <p>
                The healthcare industry is a vast and critical sector dedicated
                to the provision of medical services, the treatment of
                illnesses, and the promotion of overall health and well-being.
                It encompasses a wide range of professionals, organizations, and
                facilities, including hospitals, clinics, physicians, nurses,
                pharmaceutical companies, research institutions, insurers, and
                more.
              </p>
              <p>
                Key components of the healthcare industry include preventive
                care, diagnostics, treatment, and post-treatment care.
                Healthcare professionals work tirelessly to diagnose, manage,
                and cure diseases, improve patient outcomes, and enhance quality
                of life. Additionally, the industry is characterized by ongoing
                medical research, technological advancements, and the
                development of innovative treatments and therapies.
              </p>
              <p>
                The healthcare industry is influenced by various factors,
                including government regulations, insurance policies,
                demographic shifts, and advancements in medical technology. It
                faces challenges related to accessibility, affordability, and
                quality of care. As the world evolves, healthcare continues to
                be a dynamic and essential field, playing a central role in the
                well-being of individuals and societies.
              </p>
            </Col>
          </Row>
          <Row className="my-5">
            <Col sm={6}></Col>
            <Col sm={6} className="fs-3 fw-bold">
              Retail
            </Col>
          </Row>
          <Row className="my-5">
            <Col sm={6} className="fs-5 lh-lg alignment">
              <p>
                The retail industry is a diverse and dynamic sector of the
                economy that revolves around the sale of goods and services to
                consumers. It encompasses a wide array of businesses, from small
                boutiques to global conglomerates, and includes physical stores,
                e-commerce platforms, and a combination of both.
              </p>
              <p>
                Retailers serve as intermediaries between manufacturers or
                wholesalers and end consumers, providing a crucial link in the
                supply chain. Customer experience, convenience, and efficient
                supply chain management are central to success in this industry.
              </p>
              <p>
                The rise of e-commerce and digital technologies has transformed
                the retail landscape, leading to innovative concepts such as
                online marketplaces, subscription services, and personalized
                shopping experiences. Retailers must adapt to changing consumer
                preferences, technological advancements, and market trends to
                remain competitive and meet the evolving needs of shoppers.
              </p>
              <p>
                Overall, the retail industry is a cornerstone of the global
                economy, continuously evolving to offer consumers a diverse
                range of products and services while shaping consumer behavior
                and driving economic growth.
              </p>
            </Col>
            <Col sm={6}></Col>
          </Row>
          <Row className="my-5">
            <Col sm={6} className="fs-3 fw-bold">
              Financial
            </Col>
            <Col sm={6}></Col>
          </Row>
          <Row className="my-5">
            <Col sm={6}></Col>
            <Col sm={6} className="fs-5 lh-lg alignment">
              <p>
                The financial industry, also known as the financial sector,
                encompasses a vast and complex network of institutions,
                services, and activities that manage and facilitate the flow of
                money and resources within an economy. It includes entities such
                as banks, credit unions, investment firms, insurance companies,
                stock exchanges, and regulatory bodies.
              </p>
              <p>
                Key functions within the financial industry include the
                intermediation of funds, lending, investment management, risk
                assessment, asset protection through insurance, and facilitating
                financial transactions. The industry plays a pivotal role in
                allocating capital, supporting economic growth, and mitigating
                financial risks.
              </p>
              <p>
                Technological advancements, such as digital banking and fintech
                innovations, have transformed the financial landscape, making
                financial services more accessible and efficient for consumers
                and businesses. The financial industry is tightly regulated to
                ensure stability and protect consumers, and it plays a vital
                role in shaping the global economy by providing essential
                services and facilitating economic activities.
              </p>
            </Col>
          </Row>
          <Row className="my-5">
            <Col sm={6}></Col>
            <Col sm={6} className="fs-3 fw-bold">
              Transportation
            </Col>
          </Row>
          <Row className="my-5">
            <Col sm={6} className="fs-5 lh-lg alignment">
              <p>
                The transportation industry is a critical component of modern
                economies, facilitating the movement of people, goods, and
                services across regions, countries, and the globe. It
                encompasses a wide range of modes and services, including road,
                rail, air, maritime, and public transit.
              </p>
              <p>
                Road transportation involves vehicles like cars, trucks, and
                buses, while rail includes trains for both passengers and
                freight. Air travel connects distant locations quickly, while
                maritime transportation carries goods by sea. Public transit
                systems like subways and buses provide essential urban mobility.
              </p>
              <p>
                Efficient transportation networks are essential for trade,
                economic growth, and social connectivity, enabling the
                distribution of goods and access to jobs, education, and
                healthcare. However, the industry also faces challenges like
                traffic congestion, environmental impact, and safety concerns.
                Innovations such as electric vehicles, high-speed trains, and
                autonomous transportation are driving the industry towards more
                sustainable and efficient solutions. The transportation industry
                plays a pivotal role in shaping the way people and goods move in
                our interconnected world.
              </p>
            </Col>
            <Col sm={6}></Col>
          </Row>
          <Row className="my-5">
            <Col sm={6} className="fs-3 fw-bold">
              Energy
            </Col>
            <Col sm={6}></Col>
          </Row>
          <Row className="my-5">
            <Col sm={6}></Col>
            <Col sm={6} className="fs-5 lh-lg alignment">
              <p>
                The energy industry is a vital sector that encompasses the
                production, distribution, and utilization of various forms of
                energy to power our modern world. It plays a pivotal role in
                driving economic growth, supporting infrastructure, and
                improving quality of life. The industry includes fossil fuels
                (such as oil, natural gas, and coal), renewable energy sources
                (like solar, wind, and hydropower), nuclear energy, and emerging
                technologies.
              </p>
              <p>
                As global concerns about climate change and sustainability grow,
                there is a significant shift towards cleaner and more
                sustainable energy sources. This transition involves innovations
                in energy generation, storage, and distribution, as well as
                increased focus on energy efficiency and conservation.
              </p>
              <p>
                The energy industry is closely tied to environmental and
                geopolitical factors, making it a subject of critical importance
                for global policy and economic stability. It continues to evolve
                rapidly, with a strong emphasis on reducing carbon emissions,
                increasing energy efficiency, and adopting sustainable practices
                to meet the world's growing energy demands.
              </p>
            </Col>
          </Row>
          <Row className="my-5">
            <Col sm={6}></Col>
            <Col sm={6} className="fs-3 fw-bold">
              Manufacturing
            </Col>
          </Row>
          <Row className="my-5">
            <Col sm={6} className="fs-5 lh-lg alignment">
              <p>
                The manufacturing industry is a foundational sector of the
                economy, responsible for the production of goods ranging from
                automobiles and electronics to clothing and machinery. It
                involves the conversion of raw materials and components into
                finished products through various processes like machining,
                assembly, and fabrication.
              </p>
              <p>
                Manufacturing contributes significantly to economic development,
                providing jobs, innovation, and driving exports. It is
                characterized by a diverse range of sub-sectors, including
                automotive, aerospace, electronics, and food processing, each
                with unique challenges and opportunities.
              </p>
              <p>
                In recent years, technological advancements like automation,
                robotics, and 3D printing have revolutionized manufacturing,
                enhancing efficiency and customization. Sustainability and
                environmental concerns have also driven a shift toward greener
                and more sustainable practices.
              </p>
              <p>
                The manufacturing industry plays a pivotal role in shaping
                economies and societies, fostering innovation, and driving
                progress in countless sectors. It continues to evolve with the
                adoption of new technologies, materials, and processes to meet
                the ever-changing demands of a global marketplace.
              </p>
            </Col>
            <Col sm={6}></Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Industries;
