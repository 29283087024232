import React from "react";
import Banner from "../components/Banner";
import Introduction from "../components/Introduction";
import Gallery from "../components/Gallery";
import Services from "../components/Service";
import Clients from "../components/Clients";

const Home = () => {
  return (
    <div>
      <Banner />
      <Introduction />
      <Gallery />
      <Services />
      <Clients />
    </div>
  );
};

export default Home;
