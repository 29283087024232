import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../Pages.css";

const Partner = () => {

  return (
    <>
      <div className="content-division">
        <div className="fs-1 text-center pt-5 fw-bolder">Partners</div>
        <Container className="pt-2 text-center ms-auto">
          <Row className="my-5">
            <Col sm={6} className="fs-3 fw-bold">
              VISA
            </Col>
            <Col sm={6}></Col>
          </Row>
          <Row className="my-5">
            <Col sm={6}></Col>
            <Col sm={6} className="fs-5 lh-lg alignment">
              <p>
                Visa Inc. is a global financial services company that operates
                one of the world's largest electronic payment networks. Founded
                in 1958, Visa facilitates electronic funds transfers through its
                credit and debit cards, prepaid cards, and related payment
                products. It acts as an intermediary between banks, merchants,
                and consumers, enabling secure and convenient transactions
                worldwide.
              </p>
              <p>
                Visa's network links millions of businesses and financial
                institutions in over 200 countries and territories. The
                company's primary mission is to make digital payments
                accessible, efficient, and secure for individuals and
                businesses. Visa's products and services include credit and
                debit card issuance, transaction processing, fraud prevention,
                and data analytics.
              </p>
              <p>
                Visa plays a vital role in the global economy by providing the
                infrastructure for electronic payments, contributing to
                financial inclusion, and driving innovation in the fintech
                industry. Its ubiquitous presence and commitment to secure,
                reliable payment solutions have made Visa a trusted name in the
                financial world.
              </p>
            </Col>
          </Row>
          <Row className="my-5">
            <Col sm={6}></Col>
            <Col sm={6} className="fs-3 fw-bold">
              UHG
            </Col>
          </Row>
          <Row className="my-5">
            <Col sm={6} className="fs-5 lh-lg alignment">
              <p>
                UnitedHealth Group (UHG) is a prominent American healthcare and
                insurance conglomerate headquartered in Minnetonka, Minnesota.
                Founded in 1977, it has evolved into one of the largest and most
                diversified healthcare companies globally. UHG operates through
                two main divisions: UnitedHealthcare, which provides health
                insurance and benefits services to millions of individuals and
                businesses, and Optum, a health services and innovation company.
              </p>
              <p>
                UnitedHealthcare offers a wide range of health plans, including
                employer-sponsored, individual, and government programs,
                focusing on improving access, affordability, and quality of
                healthcare for its members.
              </p>
              <p>
                Optum, on the other hand, offers healthcare services such as
                data analytics, pharmacy care services, population health
                management, and technology solutions. It plays a crucial role in
                advancing healthcare delivery, improving patient outcomes, and
                driving innovations in the healthcare industry.
              </p>
              <p>
                UHG's mission is to help people live healthier lives and make
                healthcare work better. It's known for its commitment to
                innovation, research, and investment in healthcare solutions
                that promote well-being and drive positive changes in the
                healthcare landscape.
              </p>
            </Col>
            <Col sm={6}></Col>
          </Row>
          <Row className="my-5">
            <Col sm={6} className="fs-3 fw-bold">
              Staples
            </Col>
            <Col sm={6}></Col>
          </Row>
          <Row className="my-5">
            <Col sm={6}></Col>
            <Col sm={6} className="fs-5 lh-lg alignment">
              <p>
                Staples is a well-known American office supply retail company
                headquartered in Framingham, Massachusetts. Founded in 1986, it
                has grown into one of the largest office product retailers
                globally. Staples specializes in providing a wide range of
                office supplies, technology products, office furniture, and
                business services to consumers, small businesses, and large
                corporations.
              </p>
              <p>
                The company operates both physical retail stores and a robust
                e-commerce platform, offering customers convenience and
                flexibility in procuring essential office items. Staples is
                renowned for its extensive product catalog, competitive pricing,
                and services like printing, copying, and tech support.
              </p>
              <p>
                Staples plays a vital role in supporting businesses and
                individuals in their day-to-day operations, helping them create
                efficient and productive work environments. Its commitment to
                sustainability and responsible business practices has also made
                it a leader in promoting eco-friendly office solutions.
              </p>
            </Col>
          </Row>
          <Row className="my-5">
            <Col sm={6}></Col>
            <Col sm={6} className="fs-3 fw-bold">
              Barclays
            </Col>
          </Row>
          <Row className="my-5">
            <Col sm={6} className="fs-5 lh-lg alignment">
              <p>
                Barclays is a prominent British multinational financial services
                and banking corporation with a rich history dating back to 1690.
                Headquartered in London, it has established itself as a global
                leader in the financial industry. Barclays operates in various
                segments, including Retail Banking, Corporate and Investment
                Banking, Wealth Management, and Credit Cards.
              </p>
              <p>
                The bank offers a wide range of financial products and services
                to individual and corporate clients, including savings and
                checking accounts, loans, investment banking, and wealth
                management solutions. Barclays has a significant international
                presence, serving customers in over 40 countries.
              </p>
              <p>
                Barclays is recognized for its commitment to innovation,
                sustainability, and corporate responsibility. It has played a
                pivotal role in shaping the global financial landscape and
                continues to be a trusted partner for individuals, businesses,
                and institutions seeking financial solutions and expertise.
              </p>
            </Col>
            <Col sm={6}></Col>
          </Row>
          <Row className="my-5">
            <Col sm={6} className="fs-3 fw-bold">
              Johnson&johnson
            </Col>
            <Col sm={6}></Col>
          </Row>
          <Row className="my-5">
            <Col sm={6}></Col>
            <Col sm={6} className="fs-5 lh-lg alignment">
              <p>
                Johnson & Johnson (J&J) is a renowned American multinational
                corporation specializing in healthcare and consumer goods.
                Founded in 1886, it has grown into one of the world's largest
                and most diversified healthcare companies. J&J operates in three
                primary segments: Pharmaceuticals, Medical Devices, and Consumer
                Health.
              </p>
              <p>
                In Pharmaceuticals, the company focuses on research and
                development of innovative drugs and therapies across various
                therapeutic areas, contributing to advancements in healthcare.
              </p>
              <p>
                In the Medical Devices segment, J&J offers a wide range of
                medical devices, from surgical instruments to diagnostic
                equipment, aimed at improving patient outcomes and healthcare
                processes.
              </p>
              <p>
                Consumer Health encompasses a vast portfolio of well-known
                brands, including Band-Aid, Neutrogena, and Tylenol, offering
                over-the-counter products for personal care and wellness.
              </p>
              <p>
                J&J's commitment to innovation, sustainability, and its renowned
                Credo, which emphasizes responsibility to customers, employees,
                and the community, have made it a trusted leader in the global
                healthcare industry.
              </p>
            </Col>
          </Row>
          <Row className="my-5">
            <Col sm={6}></Col>
            <Col sm={6} className="fs-3 fw-bold">
              Microsoft
            </Col>
          </Row>
          <Row className="my-5">
            <Col sm={6} className="fs-5 lh-lg alignment">
              <p>
                Microsoft Corporation, founded in 1975 by Bill Gates and Paul
                Allen, is a global technology giant headquartered in Redmond,
                Washington. It is one of the world's largest and most
                influential technology companies, known for its diverse range of
                products, services, and contributions to the tech industry.
              </p>
              <p>
                Microsoft's core offerings include the Windows operating system,
                Office productivity suite, Azure cloud computing platform, and
                Surface hardware devices. It also plays a pivotal role in gaming
                with Xbox and the development of the cloud-based Office 365
                suite.
              </p>
              <p>
                Microsoft is at the forefront of technology innovation,
                investing heavily in areas like artificial intelligence, quantum
                computing, and cybersecurity. It is a leader in promoting
                open-source software and collaborates with businesses,
                governments, and organizations worldwide to drive digital
                transformation.
              </p>
              <p>
                With a mission to "empower every person and every organization
                on the planet to achieve more," Microsoft continues to shape the
                digital landscape and empower individuals and businesses through
                its technology solutions.
              </p>
            </Col>
            <Col sm={6}></Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Partner;
