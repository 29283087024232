import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../Client.css"; // Import your custom CSS file

const Clients = () => {
  return (
    <section id="clients" className="scrollto">
      <Container>
        <Row className="">
          <Col md={3} sm={12} className="display-flex justify-content-center align-items-center mt-lg-5">
            <div>
              <p className="client-heading">TRUST</p>
              <p className="client-title">Companies who use our services</p>
              <p className="client-subtitle">
                We are providing our services to top companies across united
                states with success
              </p>
            </div>
          </Col>
          <Col md={9} className="display-flex justify-content-center align-items-center">
            <Row>
              <Col md={4} sm={12}>
                <a href="https://www.visa.co.in/" target="_blank">
                  <img
                    src="images/visa.svg"
                    alt="Visa"
                    className="clientImage"
                  />
                  {/* <div className="client-overlay">
                    <span>VISA</span>
                  </div> */}
                </a>
              </Col>
              <Col md={4} sm={12} >
                <a href="https://www.unitedhealthgroup.com/" target="_blank">
                  <img
                    src="images/united_health_group.svg"
                    alt="United Health Group"
                    className="clientImage"
                  />
                  {/* <div className="client-overlay">
                    <span>United Health Group</span>
                  </div> */}
                </a>
              </Col>
              <Col md={4} sm={12} >
                <a href="https://www.staples.com/" target="_blank">
                  <img
                    src="images/staples.svg"
                    alt="Staples"
                    className="clientImage"
                  />
                  {/* <div className="client-overlay">
                    <span>Staples</span>
                  </div> */}
                </a>
              </Col>
              <Col md={4} sm={12}>
                <a href="https://home.barclays/" target="_blank">
                  <img
                    src="images/barclays.svg"
                    alt="Barclays"
                    className="clientImage"
                  />
                  {/* <div className="client-overlay">
                    <span>Barclays</span>
                  </div> */}
                </a>
              </Col>
              <Col md={4} sm={12} >
                <a href="https://www.jnj.in/" target="_blank">
                  <img
                    src="images/johnson-johnson.svg"
                    alt="Johnson & Johnson"
                    className="clientImage"
                  />
                  {/* <div className="client-overlay">
                    <span>Johnson & Johnson</span>
                  </div> */}
                </a>
              </Col>
              <Col md={4} sm={12}>
                <a href="https://www.microsoft.com/en-in/" target="_blank">
                  <img
                    src="images/microsoft.svg"
                    alt="Microsoft"
                    className="clientImage"
                  />
                  {/* <div className="client-overlay">
                    <span>Microsoft</span>
                  </div> */}
                </a>
              </Col>
              {/* Repeat similar code for the other 3 company logos */}
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Clients;
